import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cards from "./components/OneLineCards";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Trending from "./components/TrendingCards";
import MultiLines from "./components/MultiLinesCards";
import NotFound from "./components/NotFound";
import Trendingitems from "./components/trendingitems";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Cards />} />
        <Route path="/one-line" element={<Cards />} />
        <Route path="/h-lines" element={<Trending />} />
        <Route path="/h-lines/:shorthand" element={<Trendingitems />} />
        <Route path="/multi-lines" element={<MultiLines />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
