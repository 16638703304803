import React, { useState, useEffect } from "react";
import { Box, chakra } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Card from "./onelinecard";
import Loader from "./loader";
import {backendLink} from "../apiLink.js";

const TrendingItems = () => {
  const { shorthand } = useParams();

  const [trendingNewsItems, setTrendingNewsItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${backendLink}/api/h-lines-items?filters[shorthand][$eq]=${shorthand}&populate=*`
        );
        setTrendingNewsItems(response.data.data[0] || []);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data");
      }
    }
    fetchData();
  }, [shorthand]);

  const finalData = trendingNewsItems?.attributes?.one_line_news?.data;
  return (
    <>
      <Box pt={8} pb={0} mx="auto" textAlign="center">
        <chakra.p
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color="gray.900"
          fontWeight={600}
        >
          {trendingNewsItems?.attributes?.title || "Top News"}
        </chakra.p>
      </Box>

      {loading ? (
        <Loader />
      ) : (
        finalData.map((item) => <Card key={item.id} data={item.attributes} />)
      )}
    </>
  );
};

export default TrendingItems;
