import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import './index.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const colors = {
  modes: {
    dark: {
      background: '#000',
    },
  },
  brand: {
    900: "#010926",
    800: "#020d39",
    700: "#02124c",
    600: "#03165f",
    500:"#041a71",
    400:"#041f84",
    300:"#3856ca",
    200:"#fff",
    100:"#b4c0eb",
    50: "#e6eaf8",
  },
};
const theme = extendTheme({ colors });

root.render(
  <StrictMode>
   <ChakraProvider theme ={theme}>
   <ColorModeScript />
    <App />
   </ChakraProvider>
  </StrictMode>
);
