import React from "react";
import { chakra, Box, SimpleGrid, GridItem, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function notFound() {
  return (
    <Box px={4} py={32} mx="auto">
      <Box  minHeight={"100%"}
        w={{ base: "full", md: 11 / 12, xl: 8 / 12 }}
        textAlign={"center"}
        mx="auto"
      >
        <chakra.h5
          mb={3}
          fontSize={{ base: "xl", md: "xl" }}
          fontWeight={{ base: "bold", md: "extrabold" }}
          color="gray.900"
          _dark={{ color: "gray.100" }}
          lineHeight="shorter"
        >
          Page Not Found
        </chakra.h5>
        <chakra.p
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color="gray.500"
          lineHeight="base"
        >
          The page you are looking is currently unavailable.
        </chakra.p>
        <SimpleGrid
          alignItems={"center"}
          justifyContent={"center"}
          pt={1}
          mx="auto"
          mb={8}
        >
          <Link to={"/"}>
          <Button
            as={GridItem}
            w="200px"
            variant="solid"
            colSpan={{ base: "auto", lg: 2 }}
            size="lg"
            type="submit"
            colorScheme="brand"
            cursor="pointer"
          >
            HOME
          </Button> </Link>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
