import React from "react";
import { chakra, Flex, Icon } from "@chakra-ui/react";
import { FaGlobe, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <Flex
      w="full"
      px={20}
      py={2}
      borderTop="2px solid"
      borderTopColor="gray.200"
      alignItems="center"
      justifyContent="center"
      _dark={{
        bg: "#3e3e3e",
      }}
    >
      <Flex
        w="full"
        as="footer"
        flexDir={{ base: "column", sm: "row" }}
        align="center"
        justify="space-between"
        px="6"
        py="4"
        
        bg="white"
        _dark={{
          bg: "#3e3e3e",
        }}
      >
        <chakra.a
          href="/"
          fontSize="xl"
          fontWeight="bold"
          color="brand.500"
          _dark={{
            color: "white",
            _hover: {
              color: "gray.300",
            },
          }}
          _hover={{
            color: "gray.700",
          }}
        >
          Lines News
        </chakra.a>

        <chakra.p
          py={{ base: "2", sm: "0" }}
          color="gray.800"
          fontSize={[10, 13]}
          fontWeight={500}
          _dark={{ color: "white" }}
        >
          © 2023 | All Rights Reserved
        </chakra.p>

        <Flex mx="-2">
          <chakra.a
            href="/"
            mx="2"
            color="gray.600"
            _dark={{ color: "gray.300", _hover: { color: "gray.400" } }}
            _hover={{
              color: "brand.500",
            }}
            aria-label="Reddit"
          >
            <Icon boxSize="9" viewBox="0 0 24 24" fill="currentColor">
              <FaGlobe />
            </Icon>
          </chakra.a>

          <chakra.a
            href="https://www.linkedin.com/company/thelinesnews"
            mx="2"
            color="gray.600"
            _dark={{ color: "gray.300", _hover: { color: "gray.400" } }}
            _hover={{
              color: "brand.500",
            }}
            aria-label="Linkedin"
          >
            <Icon boxSize="9" viewBox="0 0 24 24" fill="currentColor">
              <FaLinkedin />{" "}
            </Icon>
          </chakra.a>

          <chakra.a
            href="https://www.instagram.com/thelinesnews"
            mx="2"
            color="gray.600"
            _dark={{ color: "gray.300", _hover: { color: "gray.400" } }}
            _hover={{
              color: "gray.500",
            }}
            aria-label="Instagram"
          >
            <Icon boxSize="9" viewBox="0 0 24 24" fill="currentColor">
              <FaInstagram />{" "}
            </Icon>
          </chakra.a>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
