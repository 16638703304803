import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CloseButton,
  Flex,
  HStack,
  IconButton,
  Image,
  VStack,
  chakra,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useViewportScroll } from "framer-motion";
import { FaMoon, FaSun } from "react-icons/fa";
import { FaGripLines, FaAlignJustify, FaFire } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";

const Header = () => {
  const { toggleColorMode: toggleMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const bg = useColorModeValue("white", "gray.800");
  const ref = React.useRef(null);
  const [y, setY] = React.useState(0);
  const height = ref.current ? ref.current.getBoundingClientRect() : 0;
  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);
  const cl = useColorModeValue("brand.500", "white");
  const mobileNav = useDisclosure();

  const MobileNavContent = (
    <VStack
      pos="absolute"
      top={0}
      left={0}
      right={0}
      display={mobileNav.isOpen ? "flex" : "none"}
      flexDirection="column"
      p={2}
      pb={4}
      _dark={{ bg: "#3e3e3e" }}
      zIndex={10000000}
      m={2}
      bg={bg}
      spacing={3}
      rounded="sm"
      shadow="sm"
    >
      <CloseButton
        aria-label="Close menu"
        justifySelf="self-start"
        onClick={mobileNav.onClose}
      />
      <Link to="/one-line">
        <Button
          w="full"
          variant="solid"
          colorScheme="brand"
          leftIcon={<FaGripLines />}
        >
          One-Line
        </Button>
      </Link>{" "}
      <Link to="/h-lines">
        <Button
          w="full"
          variant="solid"
          colorScheme="brand"
          leftIcon={<FaFire />}
        >
          H-Lines
        </Button>
      </Link>{" "}
      <Link to={"/multi-lines"}>
        <Button
          w="full"
          variant="solid"
          colorScheme="brand"
          leftIcon={<FaAlignJustify />}
        >
          Multi-Lines
        </Button>{" "}
      </Link>
    </VStack>
  );

  return (
    <chakra.header
      ref={ref}
      shadow={y > height ? "sm" : undefined}
      transition="box-shadow 0.2s"
      bg={bg}
      borderTop="6px solid"
      borderTopColor="brand.400"
      w="full"
      overflowY="hidden"
      borderBottomWidth={2}
      color="gray.200"
      _dark={{ bg: "#3e3e3e" }}
    >
      <chakra.div h="4.5rem" mx="auto" maxW="1200px">
        <Flex
          w="full"
          h="full"
          px="6"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex align="flex-start">
            <Link to="/">
              <HStack width={160}>
                <Image src="https://i.ibb.co/Pxv4vNj/toplogo.png" />
              </HStack>
            </Link>
          </Flex>
          <Flex>
            <HStack
              spacing="5"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              <Link to="/one-line">
                <Button
                  to="/one-line"
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  variant="outline"
                  leftIcon={<FaGripLines />}
                  boxShadow="5px 5px #041a71"
                >
                  One-Line
                </Button>
              </Link>

              <Link to="/h-lines">
                <Button
                  to="/h-lines"
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  leftIcon={<FaFire />}
                  variant="outline"
                  boxShadow="5px 5px #041a71"
                >
                  H-Lines
                </Button>
              </Link>

              <Link to={"/multi-lines"}>
                <Button
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  variant="outline"
                  boxShadow="5px 5px #041a71"
                  leftIcon={<FaAlignJustify />}
                  _hover={{
                    color: cl,
                  }}
                >
                  Multi-Lines
                </Button>
              </Link>
            </HStack>
          </Flex>

          <Flex justify="flex-end" align="center" color="gray.400">
            <HStack
              spacing="5"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              <Link to={"/"}>
              <Button colorScheme="brand" variant="solid" size="sm">
                Download App
              </Button> </Link>
            </HStack>
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              ml={{
                base: "0",
                md: "3",
              }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
            <IconButton
              display={{
                base: "flex",
                md: "none",
              }}
              aria-label="Open menu"
              fontSize="20px"
              color="gray.800"
              _dark={{
                color: "inherit",
              }}
              variant="ghost"
              icon={<AiOutlineMenu />}
              onClick={mobileNav.onOpen}
            />
          </Flex>
        </Flex>
        {MobileNavContent}
      </chakra.div>
    </chakra.header>
  );
};

export default Header;
