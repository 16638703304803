import React, { useEffect, useState } from "react";
import Multilines from "./multilines";
import { Box } from "@chakra-ui/react";
import Loadmore from "./loadmore";
import Categories from "./categories";
import axios from "axios";
import {backendLink} from "../apiLink.js";
import Loader from "./loader";


const MultiLinesNews = () => {
  const [multiLinesItems, setMultiLinesItems] = useState([]);
  let [page, setPage] = useState(2); 
  let [loading, setLoading] = useState(true);
  let [loading2, setLoading2] = useState(null);

 

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${backendLink}/api/multi-line-newss?pagination[page]=1&pagination[pageSize]=10&sort=publishedAt:desc`
        );
        setMultiLinesItems(response.data.data || []);
        setLoading(false);
        // console.log(response.data.data);
      } catch (error) {
        console.log("Error fetching data");
      }
    }
    fetchData();
  }, []);


  const loadMoreData = async () => {
    try {
      setLoading2(true);
      const response = await axios.get(
        `${backendLink}/api/multi-line-newss?pagination[page]=${page}&pagination[pageSize]=10&sort=publishedAt:desc`
      );
      setMultiLinesItems([...multiLinesItems, ...response.data.data]);
      setPage(page + 1);
      setLoading2(false);
      // console.log(response.data.data);
      // console.log(page);
    } catch (error) {
      console.log("Error fetching more data");
    }
  };


  return (
    <>
      <Categories />
      <Box
        display={"flex"}
        _dark={{ bg: "#3e3e3e" }}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {
        loading? <Loader /> :
        multiLinesItems.map((item) => (
          <Multilines key={item.id} data={item.attributes} />
        ))}
      </Box>

      {loading2 == null || loading2 === false ? (
        <Loadmore onLoadMoreClick={loadMoreData} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MultiLinesNews;
