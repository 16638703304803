import React from "react";
import { Box, Flex, Image, Link, chakra } from "@chakra-ui/react";
import {RenderNews} from "../utils/getDate.js";

export default function multilines({data}) {
  const{ newsDescription,publishedAt,iconURL,headline,hashtag } = data;
  const formattedDate = RenderNews({ item: { attributes: { publishedAt } } });

  return (
    <Flex
      _dark={{ bg: "#3e3e3e" }}
      p={4}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        rounded="lg"
        shadow="md"
        bg="white"
        _dark={{ bg: "gray.800" }}
        maxW="md"
      >
        <Image
          roundedTop="lg"
          w="full"
          h={64}
          fit="cover"
          src={iconURL}
          alt="Article"
        />

        <Box p={6}>
          <Box>
            <Flex justifyContent="space-between" alignItems="center">
              <chakra.span
                fontSize="xs"
                textTransform="uppercase"
                fontWeight={500}
                color="gray.400"
                _dark={{ color: "brand.400" }}
              >
                {hashtag}
              </chakra.span>

              <chakra.span
                fontSize="xs"
                textTransform="uppercase"
                fontWeight={500}
                color="gray.400"
                _dark={{ color: "brand.400" }}
              >
                {formattedDate}
              </chakra.span>
            </Flex>

            <Link
              display="block"
              color="gray.800"
              _dark={{ color: "white" }}
              fontWeight="bold"
              fontSize="lg"
              mt={2}
              _hover={{ textDecor: "none" }}
            >
             {headline}
            </Link>
            <chakra.p
              mt={2}
              fontSize="sm"
              color="gray.600"
              _dark={{ color: "gray.400" }}
            >
             {newsDescription}
            </chakra.p>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}
