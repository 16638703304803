export const RenderNews = ({ item }) => {
    const getTimeDifference = (publishedAt) => {
      const publishedDate = new Date(publishedAt);
      const currentDate = new Date();
      const timeDifference = currentDate - publishedDate;
      const minutes = Math.round(timeDifference / (1000 * 60));
      const hours = Math.round(timeDifference / (1000 * 3600));
      const days = Math.round(timeDifference / (1000 * 3600 * 24));
  
      if (minutes < 60) {
        return `${minutes} ${minutes === 1 ? 'min' : 'mins'} ago`;
      } else if (hours < 24) {
        return `${hours} ${hours === 1 ? 'hr' : 'hrs'} ago`;
      } else {
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      }
    };
  
    const updateDate = getTimeDifference(item.attributes.publishedAt);
    return updateDate;
  };