import React, { useState, useEffect } from "react";
import Card from "./onelinecard";
import Loadmore from "./loadmore";
import Categories from "./categories";
import axios from "axios";
import Loader from "./loader";
import {backendLink} from "../apiLink.js";

const Cards = () => {
  const [oneLineNews, setOneLineNews] = useState([]);
  let [page, setPage] = useState(2);
  let [loading, setLoading] = useState(true);
  let [loading2, setLoading2] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${backendLink}/api/one-lines?pagination[page]=1&pagination[pageSize]=10&sort=publishedAt:desc`
        );
        setOneLineNews(response.data.data || []);
        setLoading(false);
        //  console.log(response.data.data);
      } catch (error) {
        console.log("Error fetching data");
      }
    }
    fetchData();
  }, []);

  const loadMoreData = async () => {
    try {
      setLoading2(true);
      const response = await axios.get(
        `${backendLink}/api/one-lines?pagination[page]=${page}&pagination[pageSize]=10&sort=publishedAt:desc`
      );
      setOneLineNews([...oneLineNews, ...response.data.data]);
      setPage(page + 1);
      setLoading2(false);
      // console.log(response.data.data);
      // console.log(page);
    } catch (error) {
      console.log("Error fetching more data");
    }
  };

  return (
    <>
      <Categories />

      {loading ? (
        <Loader />
      ) : (
        oneLineNews.map((item) => <Card key={item.id} data={item.attributes} />)
      )}

      {loading2 == null || loading2 === false ? (
        <Loadmore onLoadMoreClick={loadMoreData} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Cards;
