import { chakra, Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const trendingcard = ({ data }) => {
  const { title, imageUrl, shorthand } = data;
  return (
    <>
      <Flex
        bg="#fff"
        _dark={{ bg: "#3e3e3e" }}
        my={8}
        mx={4}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          w={"sm"}
          mx="auto"
        >
          <Box
            bg="white"
            h={64}
            w={96}
            rounded="lg"
            shadow="xl"
            padding="50px"
            
            border={"2px solid #041a71"}
          >
            <Image
              src={imageUrl}
              alt="Background"
              rounded="lg"
              maxW="100%"
            />
          </Box>
          <Box
            w={{ base: 52, md: 72 }}
            bg="white"
            _dark={{ bg: "#3e3e3e" }}
            mt={-10}
            shadow="lg"
            rounded="lg"
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
          >
            <chakra.h3
              py={2}
              px={4}
              textAlign="center"
              fontWeight="bold"
              textTransform="uppercase"
              color="gray.800"
              _dark={{ color: "white" }}
              letterSpacing={1}
            >
              {title}
            </chakra.h3>

            <Flex
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              py={2}
              px={"auto"}
              bg="gray.200"
              _dark={{ bg: "gray.700" }}
            >
              <Link to={`/h-lines/${shorthand}`}>
                <chakra.button
                  bg="brand.500"
                  fontSize="xs"
                  fontWeight="bold"
                  color="white"
                  px={5}
                  py={2}
                  rounded="lg"
                  textTransform="uppercase"
                  _hover={{
                    bg: "gray.700",
                    _dark: { bg: "brand.700" },
                  }}
                  _focus={{
                    bg: "gray.700",
                    _dark: { bg: "brand.600" },
                    outline: "none",
                  }}
                >
                  Read Now
                </chakra.button>{" "}
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default trendingcard;
