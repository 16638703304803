import React from "react";

import { HStack, Button, Box } from "@chakra-ui/react";

import {
  FaNewspaper,
  FaFlag,
  FaGlobeAfrica,
  FaBasketballBall,
  FaMicrochip,
  FaStethoscope,
  FaBook,
  FaMusic,
  FaVoteYea,
  FaBomb,
  FaListAlt,
  FaMoneyBillAlt,
} from "react-icons/fa";

export default function App() {
  return (
    <Box py={8} px={10} _dark={{ bg: "#3e3e3e" }}>
      <HStack
        pb={6}
        _dark={{ bg: "#3e3e3e" }}
        spacing={5}
        overflowX="scroll"
        overflowY={"hidden"}
      >
        <Button
          flexShrink="0"
          variant="solid"
          colorScheme="brand"
          leftIcon={<FaNewspaper />}
          size="sm"
        >
          General
        </Button>

        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaFlag />}
          size="sm"
        >
          National
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaGlobeAfrica />}
          size="sm"
        >
          International
        </Button>

        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaBasketballBall />}
          size="sm"
        >
          Sports
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaMicrochip />}
          size="sm"
        >
          Technology
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaMoneyBillAlt />}
          size="sm"
        >
          Business
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaStethoscope />}
          size="sm"
        >
          Health
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaBook />}
          size="sm"
        >
          Education
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaMusic />}
          size="sm"
        >
          Entertainment
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaVoteYea />}
          size="sm"
        >
          Politics
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaBomb />}
          size="sm"
        >
          Crime
        </Button>
        <Button
          flexShrink="0"
          variant="outline"
          leftIcon={<FaListAlt />}
          size="sm"
        >
          Others
        </Button>
      </HStack>
    </Box>
  );
}
