import React from "react";
import { Button, Stack } from "@chakra-ui/react";


const loadmore = ({onLoadMoreClick}) =>{
  const handleClick = () => {
    if (onLoadMoreClick) {
      onLoadMoreClick();
    }
  };

  
     return (
      <Stack
        w="full"
        _dark={{ bg: "#3e3e3e" }}
        p={1}
        alignItems="center"
        justifyContent="center"
        pt={19}
        pb={20}
      >
        
          <Button
          onClick={handleClick}
          
            bgColor={"brand.500"}
            color="white"
            fontWeight="medium"
            rounded="md"
            shadow="base"
            size={"xl"}
            _focus={{
              outline: "none",
            }}
           px={8}
           py={3}
            transition="background 0.8s"
            backgroundPosition="center"
            _hover={{
              bgColor: "brand.800",
              bgGradient: `radial(circle, transparent 1%, #020d39 1%)`,
              bgPos: "center",
              backgroundSize: "15000%",
            }}
            _active={{
              bgColor: "white",
              backgroundSize: "100%",
              transition: "background 0s",
            }}
          >
            Load More...
          </Button>
        
      </Stack>
    );
  };
  
  
export default loadmore;
