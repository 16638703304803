import React, { useState, useEffect } from "react";
import Trendingcard from "./trending";
import { Box } from "@chakra-ui/react";
import axios from "axios";
import Loader from "./loader";
import {backendLink} from "../apiLink.js";

const Trending = () => {
  const [trendingItems, setTrendingItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${backendLink}/api/h-lines-items?sort[0]=priority:asc`
        );
        setTrendingItems(response.data.data || []);
        setLoading(false);
        // console.log(response.data.data);
      } catch (error) {
        console.log("Error fetching data");
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        _dark={{ bg: "#3e3e3e" }}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"center"}
      >
       {
loading? <Loader /> : trendingItems.map((item) => (
  <Trendingcard key={item.id} data={item.attributes} />
))

       }
      </Box>
    </>
  );
};

export default Trending;
