import React from "react";
import { Box, Flex, Image, Link, chakra } from "@chakra-ui/react";
import {RenderNews} from "../utils/getDate.js";

const Card = ({data}) => {
  // eslint-disable-next-line no-unused-vars
  const{hashtag, headline, iconURL, publishedAt, source} = data;
  const formattedDate = RenderNews({ item: { attributes: { publishedAt } } });

  return (
    <Flex
      _dark={{ bg: "#3e3e3e" }}
      p={3}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        mx="auto"
        px={8}
        py={4}
        rounded="md"
        shadow="xl"
        bg="white"
        _dark={{ bg: "gray.800" }}
        maxW="2xl"
       
        border={"1px solid"}
      >
        <Box  justifyContent="space-between">
          <Flex alignItems="center"  w="full"  justifyItems="stretch">
            <chakra.p
              fontSize={13}
              mt={2}
              color="gray.600"
              _dark={{ color: "gray.300" }}
            >
              {headline}
            </chakra.p>

            <Image
              mx={2}
              my={2}
              w={16}
              h={16}
              borderRadius={8}
              fit="cover"
              src={iconURL}
              alt="avatar"
            />
          </Flex>
        </Box>
        <Flex justifyContent="space-between" alignItems="center">
          <chakra.span
            fontSize={11}
            color="gray.400"
            _dark={{ color: "gray.400" }}
          >
           {formattedDate}
          </chakra.span>

          {/* <chakra.span
            fontSize={11}
            color="gray.400"
            _dark={{ color: "gray.400" }}
          >
            Source: {source}
           
          </chakra.span> */}


          <Link
            px={6}
            py={1}
            mx={1}
            bg="gray.100"
            color="gray.500"
            fontSize={11}
            fontWeight="600"
            rounded="sm"
            _hover={{ bg: "gray.200" }}
          >
            {hashtag}
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
};
export default Card;
